import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "LandingView",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/LandingView"),
  },
  {
    path: "/share/:uuid",
    name: "ShareView",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ShareView"),
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/PrivacyPolicy"),
  },
  {
    path: "/terms-conditions",
    name: "TermsConditions",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/TermsConditions"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
